import React from "react";
import { Button as AntButton } from "antd";
import PropTypes from "prop-types";
import "./style.less";

function Button(props) {
  Button.propTypes = {
    type: PropTypes.oneOf(["primary", "second"]),
    callback: PropTypes.func,
    style: PropTypes.objectOf(PropTypes.any),
    label: PropTypes.string.isRequired,
    params: PropTypes.objectOf(PropTypes.any),
  };
  Button.defaultProps = {
    type: "primary",
    callback: undefined,
    style: {},
  };

  const { label, type, callback, params } = props;
  return (
    <div className={`btn-${type}`}>
      <AntButton
        block
        type="primary"
        size="medium"
        onClick={() => {
          callback ? callback(params) : undefined;
        }}
      >
        {label}
      </AntButton>
    </div>
  );
}

export default Button;
