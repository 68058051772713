import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import { userRoleVar, restoreUserRole } from "./graphql/cache";
import "./App.less";
import Landing from "./components/Landing";
import Properties from "./components/Properties";
import Navbar from "./components/Nav";
import Appraisal from "./components/Appraisal";
import UserProfileMenu from "./components/UserProfile";
import MassiveAppraisal from "./components/MassiveAppraisal/Form";
import ModalAuth from "./components/Modals/Auth";
import AppraisalForm from "./components/AppraisalForm";
import MassiveAppraisalSummary from "./components/MassiveAppraisal/Summary";
import { Error404, Error500 } from "./components/Errors";
import UsersManagement from "./components/Admin/UsersManagement";
import PrivateRoute from "./components/PrivateRoute";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";

const { Content } = Layout;

function App() {
  const userRole = useReactiveVar(userRoleVar);
  const [modalAuthType, setModalAuthType] = useState();
  const [appraisalFormVisible, setAppraisalFormVisible] = useState(false);
  const [appraisalFormPropertyType, setAppraisalFormPropertyType] = useState();
  const [appraisalFormAddress, setAppraisalFormAddress] = useState({});

  useEffect(() => {
    restoreUserRole();
  }, []);

  return (
    <ConfigProvider locale={esES}>
      <Router>
        <AppraisalForm
          visible={appraisalFormVisible}
          setVisible={setAppraisalFormVisible}
          propertyType={appraisalFormPropertyType}
          address={appraisalFormAddress}
          setPropertyType={setAppraisalFormPropertyType}
          setAddress={(address) => setAppraisalFormAddress(address)}
          setModalAuthType={setModalAuthType}
        />
        <ModalAuth
          authType={modalAuthType}
          setAuthType={setModalAuthType}
        ></ModalAuth>
        <Layout>
          <Navbar
            setModalAuthType={setModalAuthType}
            modalAuthType={modalAuthType}
            appraisalFormVisible={appraisalFormVisible}
            setAppraisalFormVisible={setAppraisalFormVisible}
          />
          <ModalAuth
            authType={modalAuthType}
            setAuthType={setModalAuthType}
          ></ModalAuth>
          <Content>
            <Switch>
              <Route
                path="/"
                exact
                component={
                  userRole !== "admin" && userRole !== "superAdmin"
                    ? () => (
                        <Landing
                          appraisalFormPropertyType={appraisalFormPropertyType}
                          appraisalFormAddress={appraisalFormAddress}
                          setAppraisalFormPropertyType={
                            setAppraisalFormPropertyType
                          }
                          setAppraisalFormAddress={setAppraisalFormAddress}
                          setAppraisalFormVisible={setAppraisalFormVisible}
                        />
                      )
                    : UsersManagement
                }
              />
              <PrivateRoute
                path={["/properties", "/properties/:id"]}
                exact
                component={Properties}
                permissions="properties"
              />
              <Route path="/appraisals/:id" exact component={Appraisal} />
              <PrivateRoute
                path="/massive-appraisals"
                exact
                component={MassiveAppraisal}
                permissions="massive-appraisals"
              />
              <Route
                path="/massive-appraisals/:id"
                exact
                component={MassiveAppraisalSummary}
                permissions="massive-appraisals"
              />
              <Route
                path="/user"
                component={UserProfileMenu}
                permissions="profile"
              />
              <Route path="/404" component={Error404} />
              <Route path="/500" component={Error500} />
            </Switch>
          </Content>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

export default App;
//
