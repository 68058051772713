import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Marker, Popup } from "react-map-gl";
import Card from "../Cards/Card";
import SkeletonCard from "../Cards/SkeletonCard";
import { GET_PROPERTY_OFFERS } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import "./style.less";

const MapMarker = (props) => {
  MapMarker.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    zoom: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired,
    operation: PropTypes.string.isRequired,
    id: PropTypes.number,
    creatingPolygone: PropTypes.bool,
    propertyType: PropTypes.string,
    forcePopup: PropTypes.bool,
    onClickPopup: PropTypes.func,
    handleSelectProperty: PropTypes.func,
    isSelected: PropTypes.bool,
  };
  //

  const {
    id,
    latitude,
    operation,
    price,
    longitude,
    creatingPolygone,
    zoom,
    propertyType,
    forcePopup,
    onClickPopup,
    handleSelectProperty,
    isSelected,
  } = props;

  const [onHover, setHover] = useState(false);
  const [cardData, setCardData] = useState();

  const [getCardData] = useLazyQuery(GET_PROPERTY_OFFERS, {
    onCompleted: (data) => {
      var cd = data.getPropertyOffers[0];
      cd = {
        id,
        pictures: cd.pictures,
        address: cd.address,
        operation,
        propertyType,
        sellPrice: cd.price.value,
        rentPrice: cd.price.value,
        sellValorization: cd.price.value,
        rentValorization: cd.price.value,
        publicationDate: new Date(cd.publication_date),
        coveredArea: cd.real_estate.covered_area.value,
        totalArea: cd.real_estate.total_area.value,
        bedRooms: cd.real_estate.rooms || 0,
        fullBathrooms: cd.real_estate.full_bathrooms || 0,
        floors: cd.real_estate.floors,
        parkings: cd.real_estate.parking_lots,
        swimmingPool: cd.real_estate.has_swimming_pool,
        selected: false,
        liked: false,
      };
      setCardData(cd);
    },
  });
  useEffect(() => {
    if (forcePopup) {
      getCardData({
        variables: {
          propertyIds: id,
        },
      });
    }
  }, [forcePopup]);
  return (
    <div
      className="markerContainer"
      onMouseEnter={() => {
        setHover(true),
          getCardData({
            variables: {
              propertyIds: id,
            },
          });
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {(onHover || forcePopup) && !creatingPolygone ? (
        <Popup
          latitude={latitude}
          longitude={longitude}
          closeButton={false}
          closeOnClick={false}
          onClose={() => this.setState({ showPopup: false })}
          dynamicPosition={false}
          anchor="top"
        >
          {cardData ? (
            <Card
              onClick={() => onClickPopup({ ...cardData, longitude, latitude })}
              property={cardData}
              layout="simple"
              handleSelectProperty={handleSelectProperty}
              isSelected={isSelected}
            />
          ) : (
            <SkeletonCard layout="simple" />
          )}
        </Popup>
      ) : (
        <div />
      )}
      <Marker onHover setHover latitude={latitude} longitude={longitude}>
        {forcePopup || onHover ? (
          <div className="mapPin">
            <div className="mapPinDot mapPinDot--hover" />
          </div>
        ) : (
          <div className="mapPin">
            {zoom >= 16 ? (
              <div className="mapPinValue">
                {`UF ${
                  operation == "sell"
                    ? price
                      ? Math.round(price).toLocaleString("de-DE")
                      : "-"
                    : price
                    ? Math.round(price).toLocaleString("de-DE") + " /MES"
                    : "-"
                }`}
              </div>
            ) : (
              ""
            )}
            <div className="mapPinDot" />
          </div>
        )}
      </Marker>
    </div>
  );
};

export default MapMarker;
