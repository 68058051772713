import { gql } from "@apollo/client";

const GET_PROPERTY_OFFER_MARKERS = gql`
  query getPropertyOfferMarkers(
    $polygon: JSON
    $propertyType: String!
    $operationType: String!
    $publishedPriceMin: Float
    $publishedPriceMax: Float
    $roomsMin: Int
    $roomsMax: Int
    $bathroomsMin: Int
    $bathroomsMax: Int
    $coveredAreaMin: Float
    $coveredAreaMax: Float
    $totalAreaMin: Float
    $totalAreaMax: Float
    $publishedDateMin: Date
    $sorting: String
  ) {
    getPropertyOfferMarkers(
      polygon: $polygon
      propertyType: $propertyType
      operationType: $operationType
      publishedPriceMin: $publishedPriceMin
      publishedPriceMax: $publishedPriceMax
      roomsMin: $roomsMin
      roomsMax: $roomsMax
      bathroomsMin: $bathroomsMin
      bathroomsMax: $bathroomsMax
      coveredAreaMin: $coveredAreaMin
      coveredAreaMax: $coveredAreaMax
      totalAreaMin: $totalAreaMin
      totalAreaMax: $totalAreaMax
      publishedDateMin: $publishedDateMin
      sorting: $sorting
    ) {
      id
      latitude
      longitude
      operation_type
      property_type
      price__unit
      price__value
    }
  }
`;

const GET_PROPERTY_OFFERS = gql`
  query getPropertyOffers($propertyIds: [Int!]) {
    getPropertyOffers(propertyOffersIds: $propertyIds) {
      id
      price {
        value
        unit
      }
      operation_type
      publication_date
      pictures
      description
      address
      real_estate {
        property_type
        parking_lots
        floors
        latitude
        longitude
        total_area {
          value
          unit
        }
        covered_area {
          value
          unit
        }
        rooms
        full_bathrooms
        has_swimming_pool
      }
    }
  }
`;

const LOCATE_CITY = gql`
  query locateCity($latitude: Float!, $longitude: Float!) {
    locateCity(latitude: $latitude, longitude: $longitude) {
      id
      name
      state {
        id
        name
      }
    }
  }
`;

const GET_CURRENT_USER_DATA = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      firstName
      lastName
      email
      phone
      role {
        id
        name
      }
    }
  }
`;
const GET_CURRENT_USER_VALORIZATIONS = gql`
  query getMyValorizations($limit: Int!, $offset: Int!) {
    getMyValorizations(limit: $limit, offset: $offset) {
      id
      address
      propertyType
      createdAt
      sellPrice
      rentPrice
      longitude
      latitude
    }
  }
`;
const GET_CURRENT_USER_MASSIVE_VALORIZATIONS = gql`
  query getMyMassiveValorizations($limit: Int!, $offset: Int!) {
    getMyMassiveValorizations(limit: $limit, offset: $offset) {
      id
      name
      valorizationCount
      createdAt
    }
  }
`;
const GET_VALORIZATION = gql`
  query getValorization($id: Int!, $shareToken: String) {
    getValorization(id: $id, shareToken: $shareToken) {
      id
      sellPrice
      rentPrice
      address
      latitude
      longitude
      lowerBoundSellCI
      upperBoundSellCI
      lowerBoundRentCI
      upperBoundRentCI
      propertyType
      hasSwimmingPool
      totalArea
      coveredArea
      parkingLots
      bathrooms
      bedrooms
      floors
      yield
      upperBoundYieldCI
      lowerBoundYieldCI
      warehouses
      hasBalcony
    }
  }
`;

const GET_MASSIVE_VALORIZATION = gql`
  query getMassiveValorization($id: Int!) {
    getMassiveValorization(id: $id) {
      name
      createdAt
    }
  }
`;

const GET_MASSIVE_VALORIZATION_DETAILS = gql`
  query getMassiveValorizationDetail($limit: Int!, $offset: Int!, $id: Int!) {
    getMassiveValorizationDetail(limit: $limit, offset: $offset, id: $id) {
      id
      sellPrice
      rentPrice
      address
      propertyType
      latitude
      longitude
    }
  }
`;
const GET_USERS = gql`
  query getUsers(
    $textSearch: NonEmptyString
    $roleId: Int
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    getUsers(
      textSearch: $textSearch
      roleId: $roleId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      id
      lastName
      firstName
      email
      phone
      active
      createdAt
      role {
        id
        name
      }
    }
  }
`;

const GET_ROLES = gql`
  query getRoles($active: Boolean!) {
    getRoles(active: $active) {
      id
      spanishName
    }
  }
`;

export {
  GET_PROPERTY_OFFER_MARKERS,
  GET_PROPERTY_OFFERS,
  LOCATE_CITY,
  GET_VALORIZATION,
  GET_MASSIVE_VALORIZATION,
  GET_MASSIVE_VALORIZATION_DETAILS,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_VALORIZATIONS,
  GET_CURRENT_USER_MASSIVE_VALORIZATIONS,
  GET_USERS,
  GET_ROLES,
};
