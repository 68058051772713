import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { useLazyQuery } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Modal } from "antd";
import moment from "moment";

import {
  GET_PROPERTY_OFFER_MARKERS,
  GET_PROPERTY_OFFERS,
} from "../../graphql/queries";
import PropertiesFilters from "./Filters";
import PropertySummary from "./Summary";
import CardGrid from "./CardGrid";
import Map from "../Map";
import { parsePropertiesData } from "./services";
import { filtersInit } from "./constants";
import { IconMap, IconList } from "../../assets";
import "./style.less";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

export default function Properties(props) {
  // Hooks
  const history = useHistory();
  const query = useQueryParams();

  // States
  const [selectedProperties, setSelectedProperties] = useState(new Set());
  const [gridLayout, setGridLayout] = useState("vertical");
  const [resultsInsteadOfMap, setResultsInsteadOfMap] = useState(false);
  const [propertyOnHook, setPropertyOnHook] = useState();
  const [filters, setFilters] = useState(filtersInit(query));
  const [propertyCardsIndex, setPropertyCardsIndex] = useState({
    index: 1,
    max: 12,
  });
  const [polygonFilter, setPolygonFilter] = useState({
    type: "Polygon",
    coordinates: [[]],
  });
  const [dataCardState, setDataCardState] = useState();
  const [markersData, setMarkersData] = useState();
  const propertyId = Number(props.match.params.id);

  // Queries
  const [getCardData, { loading }] = useLazyQuery(GET_PROPERTY_OFFERS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const parsedData = parsePropertiesData(data.getPropertyOffers);
      setDataCardState(parsedData);
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.log(error);
      }
    },
  });

  const [getMarkers, { data, loading: markersLoading }] = useLazyQuery(
    GET_PROPERTY_OFFER_MARKERS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setMarkersData(data.getPropertyOfferMarkers);
        getCardData({
          variables: {
            propertyIds: data.getPropertyOfferMarkers
              .slice(0, propertyCardsIndex.max * propertyCardsIndex.index)
              .map((x) => x.id),
          },
        });
      },
      onError: (error) => {
        if (process.env.NODE_ENV === "development") {
          console.log(error);
        }
      },
    }
  );

  // Effects
  useEffect(() => {
    setDataCardState(null);
    if (cardScrollRef.current) {
      cardScrollRef.current.scrollTo(0, 0);
    }

    setPropertyCardsIndex({
      index: 1,
      max: 12,
    });
    if (polygonFilter.coordinates[0].length > 0) {
      getMarkers({
        variables: {
          propertyType: filters.propertyTypeFilter,
          operationType: filters.operationTypeFilter,
          publishedPriceMin: parseFloat(filters.publishedPriceFilter.minValue),
          publishedPriceMax: parseFloat(filters.publishedPriceFilter.maxValue),
          roomsMin: parseInt(filters.roomsFilter.minValue, 10),
          roomsMax: parseInt(filters.roomsFilter.maxValue, 10),
          bathroomsMin: parseInt(filters.bathroomsFilter.minValue, 10),
          bathroomsMax: parseInt(filters.bathroomsFilter.maxValue, 10),
          coveredAreaMin: parseFloat(filters.coveredAreaFilter.minValue),
          coveredAreaMax: parseFloat(filters.coveredAreaFilter.maxValue),
          totalAreaMin: parseFloat(filters.totalAreaFilter.minValue),
          totalAreaMax: parseFloat(filters.totalAreaFilter.maxValue),
          publishedDateMin: filters.publishedDateFilter
            ? new Date(moment().subtract(filters.publishedDateFilter, "months"))
                .toISOString()
                .split("T")[0]
            : undefined,
          sorting: filters.sortFilter,
          polygon: polygonFilter,
        },
      });
    }
  }, [
    polygonFilter,
    filters.propertyTypeFilter,
    filters.operationTypeFilter,
    filters.publishedPriceFilter,
    filters.roomsFilter,
    filters.bathroomsFilter,
    filters.coveredAreaFilter,
    filters.totalAreaFilter,
    filters.publishedDateFilter,
    filters.sortFilter,
  ]);

  useEffect(() => {
    if (data && data.getPropertyOfferMarkers) {
      getCardData({
        variables: {
          propertyIds: data.getPropertyOfferMarkers
            .slice(0, propertyCardsIndex.max * propertyCardsIndex.index)
            .map((x) => x.id),
        },
      });
    }
  }, [propertyCardsIndex]);

  useEffect(() => {}, [propertyId]);

  const isDesktop = useMediaQuery({ minWidth: 1200 });
  const forceGrid = useMediaQuery({ maxWidth: 767 });
  const isMobile = useMediaQuery({ maxWidth: 480 });

  useEffect(() => {
    if (forceGrid) {
      setGridLayout("vertical");
    }
  }, [isMobile]);

  const cardScrollRef = useRef(null);
  const onCardClick = (property) => {
    history.push(`/properties/${property.id}`);
  };

  const handleSelectProperty = (id, isSelected) => {
    const newSet = new Set(selectedProperties);
    if (isSelected) {
      newSet.add(id);
    } else {
      newSet.delete(id);
    }
    setSelectedProperties(newSet);
  };

  if (propertyId && !isDesktop) {
    return <PropertySummary propertyId={propertyId} />;
  }

  const hideMap = isMobile && resultsInsteadOfMap;
  const hideResults = isMobile && !resultsInsteadOfMap;

  return (
    <div className="searchView">
      {isDesktop && (
        <Modal
          visible={propertyId}
          onCancel={() => history.goBack()}
          footer={null}
          closable={false}
          className="property-summary-modal"
          width={960}
          destroyOnClose
          centered
        >
          <PropertySummary propertyId={propertyId} />
        </Modal>
      )}

      <PropertiesFilters
        filters={filters}
        setFilters={setFilters}
        dataCardState={dataCardState}
        resultsInsteadOfMap={resultsInsteadOfMap}
        markersLoading={markersLoading}
        setGridLayout={setGridLayout}
        selectedProperties={selectedProperties}
        clearSelectedProperties={() => setSelectedProperties(new Set())}
      />

      <CardGrid
        loadingMore={loading || markersLoading}
        gridLayout={gridLayout}
        dataCardState={dataCardState}
        onCardClick={onCardClick}
        seeMore={
          data?.getPropertyOfferMarkers.length >
          propertyCardsIndex.max * propertyCardsIndex.index
        }
        onSeeMoreClick={() =>
          setPropertyCardsIndex({
            max: propertyCardsIndex.max,
            index: propertyCardsIndex.index + 1,
          })
        }
        setPropertyOnHook={setPropertyOnHook}
        handleSelectProperty={handleSelectProperty}
        selectedProperties={selectedProperties}
        hidden={hideResults}
      />

      <div className={`mapContainer ${hideMap && "hidden"}`}>
        <div className="map">
          <Map
            forcePopup={propertyOnHook}
            onCardClick={onCardClick}
            address={
              query.get("searchAddress") !== "undefined" &&
              query.get("searchAddress") !== null
                ? {
                    value: query.get("searchAddress"),
                    coords: {
                      latitude: parseFloat(query.get("latitude")),
                      longitude: parseFloat(query.get("longitude")),
                    },
                  }
                : undefined
            }
            markersData={markersData}
            isLoadingMarkers={markersLoading}
            dataCard={dataCardState}
            callback={(polygon) => {
              for (var i = 0; i < polygon.coordinates[0].length; i++) {
                if (polygonFilter.coordinates[0][i]) {
                  if (
                    polygon.coordinates[0][i][0] !=
                      polygonFilter.coordinates[0][i][0] ||
                    polygon.coordinates[0][i][1] !=
                      polygonFilter.coordinates[0][i][1]
                  ) {
                    setPolygonFilter(polygon);
                    break;
                  }
                } else {
                  setPolygonFilter(polygon);
                  break;
                }
              }
            }}
            handleSelectProperty={handleSelectProperty}
            selectedProperties={selectedProperties}
          />
        </div>
      </div>

      {isMobile && (
        <Button
          className="mapOrGridButton"
          onClick={() => setResultsInsteadOfMap(!resultsInsteadOfMap)}
          type="primary"
          shape="round"
          icon={resultsInsteadOfMap ? <IconMap /> : <IconList />}
        >
          {resultsInsteadOfMap ? "Mapa" : "Listado"}
        </Button>
      )}
    </div>
  );
}

Properties.propTypes = {
  match: PropTypes.object,
};
