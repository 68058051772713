/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import MapGL, { FlyToInterpolator, Popup, Marker } from "react-map-gl";
import useSupercluster from "use-supercluster";
import MapMarker from "./Marker";
import MainMarker from "./MainMarker";
import {
  iconPoligonWhite,
  iconPolygonBlack,
  IconDelete,
  IconSatelite,
  loading,
  IconMap,
} from "../../assets";
import area from "@turf/area";
import bbox from "@turf/bbox";
import { Editor, DrawPolygonMode } from "react-map-gl-draw";
import SearchDirectionBarInMap from "../SearchDirectionBarInMap";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import "./style.less";

mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

import {
  getFeatureStyleDefault,
  getFeatureStyleSatelital,
  getEditHandleStyleSatelital,
  getEditHandleStyleDefault,
} from "./polygonStyle";

mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Map = (props) => {
  const {
    config = {
      searchbar: true,
      polygon: true,
      markerLocation: true,
      ubication: true,
      zoomButtons: true,
      zoomWithWheel: true,
      satelitalControl: true,
      propertiesAlerts: true,
      calculateArea: false,
    },
    onCardClick,
    isLoadingMarkers,
    markersData = [],
    callback,
    satelital = false,
    address,
    directionCallback,
    forcePopup,
    handleSelectProperty,
    selectedProperties,
  } = props;

  const [viewport, setViewport] = useState({
    zoom: 14,
    latitude: -33.447487,
    longitude: -70.673676,
  });

  const [actualAddress, setActualAddress] = useState({});

  const [isSatelital, setIsSatelital] = useState(false);

  const [mode, setMode] = useState();

  const [polygonOnMap, setPolygonOnMap] = useState(false);

  const [polylineCoords, setPolylineCoords] = useState([]);

  const editorRef = useRef();

  const mapRef = useRef();

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const points = markersData.map((data) => ({
    type: "Feature",
    properties: {
      cluster: false,
      markerId: data.id,
      property_type: data.property_type,
      operation_type: data.operation_type,
      price__value: data.price__value,
    },
    geometry: {
      type: "Point",
      coordinates: [parseFloat(data.longitude), parseFloat(data.latitude)],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 16 },
  });

  useEffect(() => {
    if (satelital) {
      setIsSatelital(satelital);
    }
  }, [satelital]);

  useEffect(() => {
    if (
      address &&
      address.value &&
      address.coords.latitude &&
      address.coords.longitude
    ) {
      setActualAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (editorRef.current && polylineCoords.length > 1) {
      const polylines = {
        type: "Feature",
        properties: {},
        geometry: { type: "LineString", coordinates: polylineCoords },
      };
      editorRef.current.addFeatures(polylines);
    }
  }, [polylineCoords]);

  useEffect(() => {
    if (actualAddress.coords) {
      if (
        actualAddress.coords.longitude != viewport.longitude ||
        actualAddress.coords.latitude != viewport.latitude
      ) {
        if (!viewport.height == 0) {
          setViewport({
            zoom: viewport.zoom,

            transitionInterpolator: new FlyToInterpolator({
              speed: 2,
            }),
            transitionDuration: "auto",
            latitude: actualAddress.coords.latitude,
            longitude: actualAddress.coords.longitude,
          });
        } else {
          setViewport({
            zoom: 14,
            latitude: actualAddress.coords.latitude,
            longitude: actualAddress.coords.longitude,
          });
        }
      }
    }
  }, [actualAddress]);

  function getViewportPolygonFilter({ _sw, _ne }) {
    const _nw = [_sw.lng, _ne.lat];
    const _se = [_ne.lng, _sw.lat];
    _sw = [_sw.lng, _sw.lat];
    _ne = [_ne.lng, _ne.lat];
    if (callback && !mode) {
      callback({
        type: "Polygon",
        coordinates: [[_sw, _nw, _ne, _se, _sw]],
      });
    }
  }

  function getChild(childrens) {
    var isHoverPropery = false;
    childrens.map((children) => {
      if (!isHoverPropery && children.properties.cluster) {
        isHoverPropery = getChild(supercluster.getChildren(children.id));
      } else {
        if (children.properties.markerId == forcePopup) {
          isHoverPropery = true;
        }
      }
    });
    return isHoverPropery;
  }

  const toggleEditingMode = () => {
    if (polygonOnMap || mode) {
      editorRef.current.deleteFeatures(0);
      setPolylineCoords([]);
      setMode();
      setPolygonOnMap(false);
      getViewportPolygonFilter(mapRef.current.getMap().getBounds());
    } else {
      setMode(new DrawPolygonMode());
    }
  };

  const onUpdate = (el) => {
    if (el.editType === "addTentativePosition") {
      editorRef.current.deleteFeatures(0);
      setPolylineCoords((prevState) => {
        return [...prevState, el.editContext.position];
      });
    }
    if (el.editType === "addFeature") {
      editorRef.current.deleteFeatures(0);
      setPolylineCoords([]);
      setPolygonOnMap(true);
      setMode(null);
      if (callback) {
        callback(el.data[1].geometry);
      }
    }
  };

  const GetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        setActualAddress({
          coords: {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          },
          value:
            "latitud: " +
            pos.coords.latitude +
            ", longitud: " +
            pos.coords.longitude,
        });
        if (directionCallback) {
          directionCallback({
            value:
              "latitud: " +
              pos.coords.latitude +
              ", longitud: " +
              pos.coords.longitude,
            coords: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            },
          });
        }
        if (!polygonOnMap) {
          const ne = {
            lng:
              mapRef.current.getMap().getBounds()._ne.lng -
              mapRef.current.getMap().getCenter().lng +
              pos.coords.longitude,
            lat:
              mapRef.current.getMap().getBounds()._ne.lat -
              mapRef.current.getMap().getCenter().lat +
              pos.coords.latitude,
          };
          const sw = {
            lng:
              mapRef.current.getMap().getBounds()._sw.lng -
              mapRef.current.getMap().getCenter().lng +
              pos.coords.longitude,
            lat:
              mapRef.current.getMap().getBounds()._sw.lat -
              mapRef.current.getMap().getCenter().lat +
              pos.coords.latitude,
          };

          getViewportPolygonFilter({ _sw: sw, _ne: ne });
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };
  const mapStyle = isSatelital
    ? "mapbox://styles/mapbox/satellite-v9"
    : "mapbox://styles/gustav19x/ckgirm1xp24kd19pjn45kcalg";

  return (
    <MapGL
      keyboard={false}
      {...viewport}
      scrollZoom={config.zoomWithWheel}
      doubleClickZoom={config.zoomWithWheel}
      touchZoom={config.zoomWithWheel}
      bearing={0}
      pitch={0}
      minZoom={13}
      ref={mapRef}
      preventStyleDiffing={true}
      attributionControl={false}
      width="100%"
      height="100%"
      mapStyle={mapStyle}
      onViewportChange={(nextViewport) => {
        if (!mode) {
          setViewport(nextViewport);
        }
      }}
      onLoad={() => {
        getViewportPolygonFilter(mapRef.current.getMap().getBounds());
      }}
      onInteractionStateChange={(interactionState) => {
        if (
          !interactionState.isDragging &&
          !polygonOnMap &&
          !interactionState.isZooming &&
          mapRef.current
        ) {
          getViewportPolygonFilter(mapRef.current.getMap().getBounds());
        }
      }}
      mapboxApiAccessToken={MAPBOX_TOKEN}
    >
      {config.searchbar && (
        <div className="DirectionBarContainer">
          <SearchDirectionBarInMap
            setAddress={(address) => {
              setActualAddress(address);
              if (!polygonOnMap) {
                const ne = {
                  lng:
                    mapRef.current.getMap().getBounds()._ne.lng -
                    mapRef.current.getMap().getCenter().lng +
                    address.coords.longitude,
                  lat:
                    mapRef.current.getMap().getBounds()._ne.lat -
                    mapRef.current.getMap().getCenter().lat +
                    address.coords.latitude,
                };
                const sw = {
                  lng:
                    mapRef.current.getMap().getBounds()._sw.lng -
                    mapRef.current.getMap().getCenter().lng +
                    address.coords.longitude,
                  lat:
                    mapRef.current.getMap().getBounds()._sw.lat -
                    mapRef.current.getMap().getCenter().lat +
                    address.coords.latitude,
                };

                getViewportPolygonFilter({ _sw: sw, _ne: ne });
              }
              if (directionCallback) {
                directionCallback(address);
              }
            }}
            addressName={actualAddress.value}
          />
        </div>
      )}
      {markersData &&
        clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
            cluster_id: id,
            markerId: markerId,
            property_type: property_type,
            price__value: price__value,
            operation_type: operation_type,
          } = cluster.properties;
          if (isCluster) {
            const isHover = forcePopup
              ? getChild(supercluster.getChildren(id))
              : false;
            return (
              <Marker
                key={`cluster-${id}`}
                latitude={latitude}
                longitude={longitude}
                offsetLeft={-(25 + (pointCount / points.length) * 20) / 2}
                offsetTop={-(25 + (pointCount / points.length) * 20) / 2}
              >
                <div
                  className={
                    isHover ? "selected-cluster-marker" : "cluster-marker"
                  }
                  style={{
                    width: `${25 + (pointCount / points.length) * 20}px`,
                    height: `${25 + (pointCount / points.length) * 20}px`,
                    fontSize: `${15 + (pointCount / points.length) * 5}px`,
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );

                    setViewport({
                      ...viewport,
                      latitude,
                      longitude,
                      zoom: expansionZoom,
                      transitionInterpolator: new FlyToInterpolator({
                        speed: 2,
                      }),
                      transitionDuration: "auto",
                    });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <MapMarker
              onClickPopup={onCardClick}
              key={markerId}
              id={markerId}
              latitude={latitude}
              longitude={longitude}
              operation={operation_type}
              price={price__value}
              propertyType={property_type}
              creatingPolygone={mode !== undefined && mode !== null}
              zoom={viewport.zoom}
              forcePopup={forcePopup == markerId}
              handleSelectProperty={handleSelectProperty}
              isSelected={selectedProperties.has(markerId)}
            />
          );
        })}
      {actualAddress.coords ? (
        <MainMarker
          latitude={actualAddress.coords.latitude}
          longitude={actualAddress.coords.longitude}
          isSatelital={isSatelital}
        />
      ) : (
        <></>
      )}
      {polygonOnMap && config.calculateArea && (
        <>
          <Popup
            className="areaPopup"
            closeButton={false}
            closeOnClick={false}
            dynamicPosition={false}
            latitude={bbox(editorRef.current.getFeatures()[0].geometry)[3]}
            longitude={
              (bbox(editorRef.current.getFeatures()[0].geometry)[0] +
                bbox(editorRef.current.getFeatures()[0].geometry)[2]) /
              2
            }
          >
            <div>{Math.round(area(editorRef.current.getFeatures()[0]))} m²</div>
          </Popup>
        </>
      )}
      <Editor
        ref={editorRef}
        style={{ width: "100%", height: "100%" }}
        clickRadius={12}
        mode={mode}
        onUpdate={onUpdate}
        editHandleShape={"circle"}
        editHandleStyle={
          isSatelital ? getEditHandleStyleSatelital : getEditHandleStyleDefault
        }
        featureStyle={
          isSatelital ? getFeatureStyleSatelital : getFeatureStyleDefault
        }
      />
      <div className="mapboxgl-ctrl-top-right">
        <div className="alert">
          {mode ? (
            polylineCoords.length < 3 ? (
              <>
                <div>Dibujar filtro por polígono</div>
              </>
            ) : (
              polylineCoords.length > 2 && (
                <>
                  <div>Cerrar dibujo para terminar</div>
                </>
              )
            )
          ) : (
            config.propertiesAlerts &&
            (isLoadingMarkers ? (
              <>
                <img className="loadingIcon" src={loading} />
                {polygonOnMap ? (
                  <div>Aplicando filtro por polígono</div>
                ) : (
                  <div>Buscando propiedades...</div>
                )}
              </>
            ) : (
              <>
                <div>{markersData.length} Resultados encontrados</div>
              </>
            ))
          )}
        </div>
      </div>
      <div className="mapboxgl-ctrl-bottom-right">
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl">
          {config.polygon && (
            <button
              className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon"
              title="Polygon tool (p)"
              onClick={toggleEditingMode}
              style={
                mode || polygonOnMap
                  ? {
                      border: "2px solid #8186f7",
                      backgroundColor: "#8186f7",
                    }
                  : { border: "2px solid #ffffff", backgroundColor: "#ffffff" }
              }
            >
              {polygonOnMap ? (
                <img src={IconDelete} className="deleteTrashIcon" />
              ) : mode ? (
                <img src={iconPoligonWhite} />
              ) : (
                <img src={iconPolygonBlack} />
              )}
            </button>
          )}
          {config.markerLocation && actualAddress.coords && (
            <button
              title="Center Marker"
              onClick={() => {
                setViewport({
                  ...viewport,

                  transitionInterpolator: new FlyToInterpolator({
                    speed: 2,
                  }),
                  transitionDuration: "auto",
                  latitude: actualAddress.coords.latitude,
                  longitude: actualAddress.coords.longitude,
                });
              }}
            >
              <img
                src="https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/9c64cfe3-bb3b-4ae8-b5a6-d2f39d21ff87/d3jme6i-8c702ad4-4b7a-4763-9901-99f8b4f038b0.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3sicGF0aCI6IlwvZlwvOWM2NGNmZTMtYmIzYi00YWU4LWI1YTYtZDJmMzlkMjFmZjg3XC9kM2ptZTZpLThjNzAyYWQ0LTRiN2EtNDc2My05OTAxLTk5ZjhiNGYwMzhiMC5wbmcifV1dLCJhdWQiOlsidXJuOnNlcnZpY2U6ZmlsZS5kb3dubG9hZCJdfQ.JAMbat4sBPIi4yMAvudrMIWf7vOdCgts3vn-JqFq1Oo"
                className="iconMapPin"
              />
            </button>
          )}
          {config.ubication && (
            <button
              title="Center GPS"
              onClick={() => {
                GetUserLocation();
              }}
            >
              <img className="iconCenterGPS" />
            </button>
          )}
          {config.zoomButtons && (
            <div className="zoomButtons">
              <button
                onClick={() =>
                  setViewport({
                    ...viewport,
                    transitionDuration: 200,
                    transitionInterpolator: new FlyToInterpolator(),
                    zoom: viewport.zoom + 1,
                  })
                }
              >
                <img className="iconPlus" />
              </button>
              <button
                onClick={() =>
                  setViewport({
                    ...viewport,
                    transitionDuration: 200,
                    transitionInterpolator: new FlyToInterpolator(),
                    zoom: viewport.zoom - 1,
                  })
                }
              >
                <img className="iconMinus" />
              </button>
            </div>
          )}
        </div>
      </div>
      {config.satelitalControl && (
        <div className="mapbox-satelital-control mapboxgl-ctrl-group mapboxgl-ctrl">
          <div className="mapbox-satelital-button-group">
            <button
              onClick={() => {
                setIsSatelital(false);
              }}
              className={`satelitalControl ${
                !isSatelital ? "active" : "inactive"
              }`}
            >
              <IconMap />
              Mapa
            </button>
            <button
              onClick={() => {
                setIsSatelital(true);
              }}
              className={`satelitalControl ${
                !isSatelital ? "inactive" : "active"
              }`}
            >
              <IconSatelite />
              Satelite
            </button>
          </div>
        </div>
      )}
    </MapGL>
  );
};

export default Map;

Map.propTypes = {
  dataCard: PropTypes.arrayOf(PropTypes.object),
  markersData: PropTypes.arrayOf(PropTypes.object),
  callback: PropTypes.func,
  satelital: PropTypes.bool,
  address: PropTypes.object,
  config: PropTypes.object,
  isLoadingMarkers: PropTypes.bool,
  directionCallback: PropTypes.func,
  onCardClick: PropTypes.func,
  forcePopup: PropTypes.number,
  handleSelectProperty: PropTypes.func,
  selectedProperties: PropTypes.object,
};
