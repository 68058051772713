import React from "react";
import PropTypes from "prop-types";
import DataCard from "../Cards/DataCard";
import {
  IconHouseCoin,
  IconLike,
  IconSaved,
  iconUserProfileImage,
} from "../../assets";
import { useMediaQuery } from "react-responsive";

export default function UserProfileData(props) {
  const { userName, lastName, userRole, userEmail, userPhone } = props;

  const isBigScreen = useMediaQuery({ minWidth: 1440 });
  const isIpadPro = useMediaQuery({ minWidth: 834, maxWidth: 1024 });
  const cardSize = isBigScreen || isIpadPro ? "large" : "medium";

  return (
    <div className="user-profile-data-view">
      <div className="user-profile-data-profile">
        <img
          src={iconUserProfileImage}
          alt=""
          className="user-profile-data-avatar"
        />
        <h2>{`Perfil de ${userName} ${lastName}`}</h2>
        <span>
          Perfil
          <br />
          <em>{userRole}</em>
        </span>
        <span>
          Email
          <br />
          <em>{userEmail}</em>
        </span>
        <span>
          Celular
          <br />
          <em>{userPhone ?? "Sin información"}</em>
        </span>
      </div>

      <div className="user-profile-data-cards">
        <DataCard
          title="Propiedades valorizadas este mes"
          body="123"
          footer="Valorizaciones realizadas por TassApp"
          icon={IconHouseCoin}
          size={cardSize}
        ></DataCard>
        <DataCard
          title="Total propiedades valorizadas"
          body="1237"
          footer="Valorizaciones realizadas por TassApp"
          icon={IconHouseCoin}
          size={cardSize}
        ></DataCard>
        <DataCard
          title="Propiedades guardadas"
          body="52"
          footer="Propiedades publicadas en TassApp"
          icon={IconSaved}
          size={cardSize}
        ></DataCard>
        <DataCard
          title="Propiedades favoritas"
          body="24"
          footer="Propiedades publicadas en TassApp"
          icon={IconLike}
          size={cardSize}
        ></DataCard>
      </div>
    </div>
  );
}

UserProfileData.propTypes = {
  userName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  userPhone: PropTypes.string.isRequired,
};
