import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useReactiveVar, useMutation, useLazyQuery } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { Button, Drawer, Form, Input, Modal, Select, Switch } from "antd";
import Icon from "@ant-design/icons";

import { userTokenVar, restoreUserToken } from "../../graphql/cache";
import { LOCATE_CITY } from "../../graphql/queries";
import { CREATE_VALORIZATION } from "../../graphql/mutations";
import Map from "../Map";
import { IconArrowLeft } from "../../assets";
import "./style.less";
// import { Redirect } from "react-router-dom";
const util = require("util");

const { Option } = Select;

export default function AppraisalForm(props) {
  const { visible, setVisible } = props;
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (isMobile) {
    return (
      <Drawer
        visible={visible}
        closable={false}
        placement="bottom"
        width="100%"
        height="calc(100% - 48px)"
        className="appraisal-drawer"
        zIndex={9}
      >
        <Button
          type="link"
          icon={<Icon component={IconArrowLeft} />}
          size="small"
          onClick={handleCancel}
          className="btn-go-back"
        >
          Volver
        </Button>
        <AppraisalFormContent {...props} />
      </Drawer>
    );
  }

  return (
    <Modal
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width="80%"
      height="90vh"
      className="appraisal-modal"
      zIndex={1000}
    >
      <AppraisalFormContent {...props} />
    </Modal>
  );
}

AppraisalForm.propTypes = {
  propertyType: PropTypes.string,
  setPropertyType: PropTypes.func.isRequired,
  address: PropTypes.object,
  setAddress: PropTypes.func,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  setModalAuthType: PropTypes.func,
};

const AppraisalFormContent = (props) => {
  const {
    setVisible,
    propertyType,
    address,
    setPropertyType,
    setAddress,
    setModalAuthType,
  } = props;

  const userToken = useReactiveVar(userTokenVar);
  const history = useHistory();
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [coveredAreaFormState, setCoveredAreaFormState] = useState();
  const [totalAreaFormState, setTotalAreaFormState] = useState();
  const [roomsFormState, setRoomsFormState] = useState();
  const [bathroomsFormState, setBathroomsFormState] = useState();
  const [parkingsFormState, setParkingsFormState] = useState();
  const [floorsFormState, setFloorsFormState] = useState();
  const [poolFormState, setPoolFormState] = useState();
  const [warehousesFormState, setwarehousesFormState] = useState();
  const [isDuplexFormState, setIsDuplexFormState] = useState();
  const [hasBalconyFormState, setHasBalconyFormState] = useState();

  function clearData() {
    form.resetFields();
    setAddress();
    setPropertyType();
    setCoveredAreaFormState();
    setTotalAreaFormState();
    setRoomsFormState();
    setBathroomsFormState();
    setParkingsFormState();
    setFloorsFormState();
    setPoolFormState();
    setwarehousesFormState();
    setIsDuplexFormState();
    setHasBalconyFormState();
    setVisible(false);
  }

  const [createValorization, { loading }] = useMutation(CREATE_VALORIZATION, {
    onCompleted: (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Valorization Success", data);
      }
      clearData();
      history.push(`/appraisals/${data.createValorization.id}`);
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Valorization Failed:", error);
        console.log(util.inspect(error, false, null, true));
      }
    },
  });
  const [getLocation] = useLazyQuery(LOCATE_CITY, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Location Success", data);
      }
      createValorization({
        variables:
          propertyType === "house"
            ? {
                createValorizationInput: {
                  propertyType: propertyType ? propertyType : "house",
                  address: address.value,
                  city: data.locateCity.name,
                  state: data.locateCity.state.name,
                  hasSwimmingPool: poolFormState ? true : false,
                  latitude: address.coords.latitude,
                  longitude: address.coords.longitude,
                  totalArea: parseFloat(totalAreaFormState),
                  coveredArea: parseFloat(coveredAreaFormState),
                  parkingLots: parseInt(parkingsFormState, 10),
                  bathrooms: parseInt(bathroomsFormState, 10),
                  floors: parseInt(floorsFormState, 10),
                  bedrooms: parseInt(roomsFormState, 10),
                },
              }
            : {
                createValorizationInput: {
                  propertyType: propertyType ? propertyType : "apartment",
                  address: address.value,
                  city: data.locateCity.name,
                  state: data.locateCity.state.name,
                  latitude: address.coords.latitude,
                  longitude: address.coords.longitude,
                  totalArea: parseFloat(totalAreaFormState),
                  coveredArea: parseFloat(coveredAreaFormState),
                  parkingLots: parseInt(parkingsFormState, 10),
                  bathrooms: parseInt(bathroomsFormState, 10),
                  bedrooms: parseInt(roomsFormState, 10),
                  isDuplex: isDuplexFormState ? isDuplexFormState : false,
                  hasBalcony: hasBalconyFormState ? hasBalconyFormState : false,
                  warehouses: parseInt(warehousesFormState, 10),
                },
              },
      });
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Location Failed:", error);
        if (error.graphQLErrors) {
          console.log(error.graphQLErrors);
        }
      }
    },
  });

  useEffect(() => form.resetFields(), [propertyType]);

  useEffect(() => {
    restoreUserToken();
  }, []);

  useEffect(() => {
    restoreUserToken();
  }, [userToken]);

  const onFinish = (values) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Form Success:", values);
    }
    if (address) {
      getLocation({
        variables: {
          latitude: address.coords.latitude,
          longitude: address.coords.longitude,
        },
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (process.env.NODE_ENV === "development") {
      console.log("Failed:", errorInfo);
    }
  };

  const inputSize = isMobile ? "small" : "middle";
  const buttonSize = isMobile ? "middle" : "large";

  return (
    <div className="appraisal-content">
      <div className="appraisal-form">
        <h1>¡Ya estamos casi!</h1>
        <p>Necesitamos unos cuantos datos para poder valorizar la propiedad</p>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout={"vertical"}
          initialValues={{ propertyType: propertyType }}
        >
          <Form.Item
            name="propertyType"
            label="Tipo de propiedad"
            rules={[
              {
                required: true,
                message: "Ingresa el tipo de propiedad",
              },
            ]}
            className="mobile-col-2"
          >
            <Select
              onChange={(x) => setPropertyType(x)}
              value={propertyType}
              placeholder={"Casa o Departamento"}
              size={inputSize}
            >
              <Option value="house">Casa</Option>
              <Option value="apartment">Departamento</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="coveredArea"
            label="Tamaño construido (área útil sin consederar patio/terraza)"
            rules={[
              {
                required: true,
              },
            ]}
            className="col-2"
          >
            <Input
              value={coveredAreaFormState}
              onChange={(x) => {
                setCoveredAreaFormState(x.target.value);
              }}
              type={"number"}
              placeholder="Área útil en m²"
              size={inputSize}
            />
          </Form.Item>

          <Form.Item
            name="totalArea"
            label="Tamaño terreno (área total considerando patio/terraza)"
            rules={[
              {
                required: true,
              },
            ]}
            className="col-2"
          >
            <Input
              value={totalAreaFormState}
              onChange={(x) => {
                setTotalAreaFormState(x.target.value);
              }}
              type={"number"}
              placeholder="Área total en m²"
              size={inputSize}
            />
          </Form.Item>

          <Form.Item
            name="rooms"
            label="Cantidad de habitaciones"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              value={roomsFormState}
              onChange={(x) => {
                setRoomsFormState(x.target.value);
              }}
              type={"number"}
              placeholder="Habitaciones"
              size={inputSize}
            />
          </Form.Item>
          <Form.Item
            name="bathrooms"
            label="Cantidad de baños"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              value={bathroomsFormState}
              onChange={(x) => {
                setBathroomsFormState(x.target.value);
              }}
              type={"number"}
              placeholder="Baños"
              size={inputSize}
            />
          </Form.Item>
          <Form.Item
            name="parkings"
            label="Capacidad estacionamiento"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              value={parkingsFormState}
              onChange={(x) => {
                setParkingsFormState(x.target.value);
              }}
              type={"number"}
              placeholder="Estacionamiento"
              size={inputSize}
            />
          </Form.Item>
          {propertyType === "house" ? (
            <Form.Item
              name="floors"
              label="Cantidad de pisos"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                value={floorsFormState}
                onChange={(x) => {
                  setFloorsFormState(x.target.value);
                }}
                type={"number"}
                placeholder="Pisos"
                size={inputSize}
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="warehouses"
              label="Bodegas"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input
                value={warehousesFormState}
                onChange={(x) => {
                  setwarehousesFormState(x.target.value);
                }}
                type={"number"}
                placeholder="Bodegas"
                size={inputSize}
              />
            </Form.Item>
          )}
          {propertyType === "house" ? (
            <Form.Item
              name="pool"
              label="¿Propiedad tiene piscina?"
              valuePropName="checked"
              className="inline col-2"
            >
              <Switch
                checked={poolFormState}
                onChange={(x) => {
                  setPoolFormState(x);
                }}
                size={inputSize}
              />
            </Form.Item>
          ) : (
            <Fragment>
              <Form.Item
                name="isDuplex"
                label="¿Es duplex?"
                valuePropName="checked"
                className="inline"
              >
                <Switch
                  checked={isDuplexFormState}
                  onChange={(x) => {
                    setIsDuplexFormState(x);
                  }}
                  size={inputSize}
                />
              </Form.Item>
              <Form.Item
                name="hasBalcony"
                label="¿Tiene bálcon?"
                valuePropName="checked"
                className="inline"
              >
                <Switch
                  checked={hasBalconyFormState}
                  onChange={(x) => {
                    setHasBalconyFormState(x);
                  }}
                  size={inputSize}
                />
              </Form.Item>
            </Fragment>
          )}

          {userToken ? (
            <Button
              type="primary"
              htmlType="submit"
              size={buttonSize}
              disabled={address && address.coords ? false : true}
              loading={loading}
            >
              VALORIZAR PROPIEDAD
            </Button>
          ) : (
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setModalAuthType("signUp");
              }}
            >
              REGÍSTRATE PARA VALORIZAR
            </Button>
          )}
        </Form>
      </div>
      <div className="map-container">
        <Map
          directionCallback={(address) => {
            setAddress(address);
          }}
          config={{
            searchbar: true,
            polygon: true,
            markerUbication: true,
            ubication: true,
            zoomButtons: true,
            satelitalControl: true,
            propertiesAlerts: false,
            calculateArea: true,
          }}
          satelital
          address={address}
        />
      </div>
    </div>
  );
};

AppraisalFormContent.propTypes = AppraisalForm.propTypes;
