export const parsePropertiesData = (propertiesData) => {
  const parsedData = propertiesData.map((x) => ({
    id: x.id,
    pictures: x.pictures,
    address: x.address,
    operation: x.operation_type,
    propertyType: x.real_estate.property_type,
    latitude: x.real_estate.latitude,
    longitude: x.real_estate.longitude,
    sellPrice: x.price.value,
    rentPrice: x.price.value,
    sellValorization: x.price.value,
    rentValorization: x.price.value,
    publicationDate: new Date(x.publication_date),
    coveredArea: x.real_estate.covered_area.value,
    totalArea: x.real_estate.total_area.value,
    bedRooms: x.real_estate.rooms || 0,
    fullBathrooms: x.real_estate.full_bathrooms || 0,
    floors: x.real_estate.floors,
    parkings: x.real_estate.parking_lots,
    swimmingPool: x.real_estate.has_swimming_pool,
    selected: false,
    liked: false,
  }));
  return parsedData;
};
