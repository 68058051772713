import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import DataCard from "../Cards/DataCard";
import Map from "../Map";
import { Button } from "antd";
import Icon from "@ant-design/icons";

import {
  IconDownload,
  IconTassApp,
  IconCoinHand,
  IconKeyHand,
  IconLink,
} from "../../assets";
import { userTokenVar, restoreUserToken } from "../../graphql/cache";
import { useQuery, useReactiveVar } from "@apollo/client";
import { GET_VALORIZATION } from "../../graphql/queries";
import {
  GET_REPORT_URL,
  GET_KEY_FOR_SHARE_APPRAISAL,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import "./style.less";

const propertyTypeFormatter = { house: "Casa", apartment: "Departamento" };

function Appraisal({ valorizationId, shareToken }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [hideProperties, setHideProperties] = useState(true);

  const { loading, data } = useQuery(GET_VALORIZATION, {
    variables: { id: valorizationId, shareToken: shareToken },

    onCompleted: (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Valorization Succeed:", data);
      }
    },
    onError: (error) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Valorization Failed:", error);
        if (error.graphQLErrors) {
          console.log(error.graphQLErrors);
        }
      }
    },
  });

  const [createdPDF, { loading: loadingPdf }] = useMutation(GET_REPORT_URL, {
    onCompleted: (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Valorization Succeed:", data);
      }
      window.open(data.getReportUrl);
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === "development") {
        if (graphQLErrors) {
          console.log("graphQLErrors", graphQLErrors);
        }
        if (networkError) {
          console.log("networkError", networkError);
        }
      }
    },
  });

  const [
    createShareLink,
    { data: isLink, loading: loadingShareLink },
  ] = useMutation(GET_KEY_FOR_SHARE_APPRAISAL, {
    onCompleted: (data) => {
      if (process.env.NODE_ENV === "development") {
        console.log("Valorization Succeed:", data);
      }
      navigator.clipboard.writeText(
        `${window.location.href}?shareToken=${data.shareValorization}`
      );
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV === "development") {
        console.log("graphQLErrors", graphQLErrors);
        console.log("networkError", networkError);
      }
      if (graphQLErrors) {
        // pass
      }
      if (networkError) {
        for (let err of networkError.result.errors) {
          switch (err.extensions.code) {
            case "BAD_USER_INPUT":
              userTokenVar("");
              window.location.href = "/";
          }
        }
      }
    },
  });

  const downloadPDF = () => {
    createdPDF({
      variables: {
        valorizationId: valorizationId,
        shareToken: shareToken,
      },
    });
  };

  const copyLink = () => {
    if (shareToken) {
      navigator.clipboard.writeText(window.location.href);
    } else {
      createShareLink({
        variables: {
          id: valorizationId,
        },
      });
    }
  };

  const buttonSize = isMobile ? "middle" : "large";
  const cardSize = isMobile ? "small" : "middle";

  return loading ? (
    <></>
  ) : data && data.getValorization ? (
    <div className="appraisal-view">
      <div className="appraisal-summary">
        <h1 className="appraisal-header">Detalle de valorización</h1>

        <div className="appraisal-summary-content">
          <div className="appraisal-summary-property">
            <p className="appraisal-summary-address">
              {data.getValorization.address ||
                "Dirección exacta no disponible :("}
            </p>
            <div className="map-container">
              <Map
                config={{
                  searchbar: false,
                  polygon: false,
                  markerUbication: true,
                  ubication: false,
                  zoomButtons: true,
                  satelitalControl: false,
                  propertiesAlerts: false,
                  calculateArea: false,
                }}
                withSearchBar={false}
                address={{
                  value: data.getValorization.address,
                  coords: {
                    latitude: data.getValorization.latitude,
                    longitude: data.getValorization.longitude,
                  },
                }}
              ></Map>
            </div>

            <div
              className={`summary-grid${
                isMobile && hideProperties ? " summarized" : ""
              }`}
            >
              <span>
                <strong>Tipo de propiedad: </strong>
                {propertyTypeFormatter[data.getValorization.propertyType]}
              </span>

              <span>
                <strong>Cantidad de habitaciones: </strong>
                {data.getValorization.bedrooms}
              </span>

              <span>
                <strong>Tamaño construido (área útil): </strong>
                {`${data.getValorization.coveredArea} m²`}
              </span>

              <span>
                <strong> Cantidad de baños: </strong>
                {data.getValorization.bathrooms}
              </span>

              <span className="summarized-hide">
                <strong> Tamaño terreno (área total): </strong>
                {`${data.getValorization.totalArea} m²`}
              </span>

              {data.getValorization.propertyType === "house" ? (
                <>
                  <span className="summarized-hide">
                    <strong> Cantidad de pisos: </strong>
                    {data.getValorization.floors}
                  </span>
                </>
              ) : (
                <>
                  <span className="summarized-hide">
                    <strong> Cantidad de bodegas: </strong>
                    {data.getValorization.warehouses}
                  </span>
                </>
              )}

              <span className="summarized-hide">
                <strong>Capacidad estacionamientos: </strong>
                {`${data.getValorization.parkingLots} autos`}
              </span>

              {data.getValorization.propertyType === "house" ? (
                <>
                  <span className="summarized-hide">
                    <strong>¿Propiedad tiene piscina? </strong>
                    {data.getValorization.hasSwimmingPool ? "Si" : "No"}
                  </span>
                </>
              ) : (
                <>
                  <span className="summarized-hide">
                    <strong>¿Propiedad con balcón? </strong>
                    {data.getValorization.hasBalcony ? "Si" : "No"}
                  </span>
                </>
              )}
            </div>
            <Button
              size="small"
              onClick={() => setHideProperties(!hideProperties)}
              className="btn-see-more"
            >
              {hideProperties ? "Ver más" : "Ver menos"}
            </Button>
          </div>

          <div className="appraisal-summary-result">
            <h2>Valores estimados por TassApp</h2>

            <DataCard
              size={cardSize}
              icon={IconTassApp}
              title={"Valor estimado de venta"}
              body={`UF ${data.getValorization.sellPrice.toLocaleString(
                "de-DE"
              )}`}
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconTassApp}
              title={"Rango estimado de venta"}
              body={`UF ${parseInt(
                data.getValorization.lowerBoundSellCI,
                10
              ).toLocaleString("de-DE")}-${parseInt(
                data.getValorization.upperBoundSellCI,
                10
              ).toLocaleString("de-DE")}`}
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconKeyHand}
              title={"Valor estimado de arriendo"}
              body={`UF ${data.getValorization.rentPrice.toLocaleString(
                "de-DE"
              )}/mes`}
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconKeyHand}
              title={"Rango estimado de arriendo"}
              body={`UF ${parseInt(
                data.getValorization.lowerBoundRentCI,
                10
              ).toLocaleString("de-DE")}-${parseInt(
                data.getValorization.upperBoundRentCI,
                10
              ).toLocaleString("de-DE")}/mes`}
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconCoinHand}
              title={"Rentabilidad anual"}
              body={`${(data.getValorization.yield * 100).toLocaleString(
                "de-DE"
              )}%`}
            ></DataCard>
            <DataCard
              size={cardSize}
              icon={IconCoinHand}
              title={"Rango de rentabilidad"}
              body={`${(
                data.getValorization.lowerBoundYieldCI * 100
              ).toLocaleString("de-DE")}%-${(
                data.getValorization.upperBoundYieldCI * 100
              ).toLocaleString("de-DE")}%`}
            ></DataCard>
            <Button
              loading={loadingPdf}
              size={buttonSize}
              type="primary"
              onClick={downloadPDF}
              icon={<Icon component={IconDownload} />}
            >
              Descargar PDF
            </Button>
            <Button
              size={buttonSize}
              type="primary"
              loading={loadingShareLink}
              onClick={copyLink}
              icon={<Icon component={IconLink} />}
            >
              {isLink && !loadingShareLink && (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={"copy-popup"}
                >
                  Enlace copiado!
                </div>
              )}
              Copiar Enlace
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

Appraisal.propTypes = {
  valorizationId: PropTypes.number.isRequired,
  shareToken: PropTypes.string,
};

export default function AppraisalView(props) {
  AppraisalView.propTypes = {
    match: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any),
  };
  const userToken = useReactiveVar(userTokenVar);

  useEffect(() => {
    restoreUserToken();
  }, []);

  const { match } = props;
  let params = new URLSearchParams(props.location.search);
  return userToken || params.get("shareToken") ? (
    <Appraisal
      shareToken={params.get("shareToken")}
      valorizationId={parseInt(match.params.id, 10) || 0}
    ></Appraisal>
  ) : (
    <></>
  );
}
