import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Button, Image } from "antd";
import Icon from "@ant-design/icons";

import { GET_PROPERTY_OFFERS } from "../../graphql/queries";
import Map from "../Map";
import DataCard from "../Cards/DataCard";
import Loading from "../Loading";
import { parsePropertiesData } from "./services";
import {
  IconBathroom,
  IconBedroom,
  IconSquareMeters,
  IconPrice,
  IconArrowLeft,
  IconTassApp,
  IconCoinHand,
  IconKeyHand,
} from "../../assets";
import "./summary.less";
import { useMediaQuery } from "react-responsive";

const operationFormatter = { sell: "venta", rent: "arriendo" };
const propertyTypeFormatter = { house: "Casa", apartment: "Departamento" };

export default function PropertySummary({ propertyId }) {
  const history = useHistory();
  const [propertyData, setPropertyData] = useState();
  const isTablet = useMediaQuery({ maxWidth: 1023 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  useQuery(GET_PROPERTY_OFFERS, {
    variables: {
      propertyIds: [propertyId],
    },
    onCompleted: (data) => {
      const parsedData = parsePropertiesData(data.getPropertyOffers);
      setPropertyData(parsedData[0]);
    },
  });

  if (!propertyData) {
    return <Loading />;
  }

  const displayPrice =
    propertyData.operation === "sell"
      ? `UF ${
          propertyData.sellPrice
            ? Math.min(propertyData.sellPrice, 999999).toLocaleString("de-DE")
            : "-"
        }`
      : `UF ${
          propertyData.rentPrice
            ? Math.min(propertyData.rentPrice, 999999).toLocaleString("de-DE")
            : "-"
        }/mes`;

  const explainText =
    isTablet || isMobile
      ? "Los intervalos se calculan mediante un intervalo de confianza con confiabilidad de un 90%, basándose en los datos de todo el mercado inmibiliario. Esto significa que la propiedad tiene 90% de probabilidad de tener un valor dentro de ese rango."
      : "El intervalo para la estimación de TassApp se obtiene con un intervalo de confianza que se calcula en base a la variabilidad de las predicciones del modelo de inteligencia artificial. Para su cálculo, se fijó un intervalo de confianza del 90%, lo que representa la probabilidad de que el precio real de venta de la propiedad se encuentre dentro de este intervalo.";
  const cardSize = isMobile ? "small" : "middle";

  return (
    <div className="property-summary-container">
      <div className="property-data">
        <Button
          className="btn-back"
          onClick={() => history.goBack()}
          icon={<Icon component={IconArrowLeft} />}
          type="link"
        >
          Volver
        </Button>
        <h2>{`${
          propertyTypeFormatter[propertyData.propertyType] || "Casa"
        } en ${operationFormatter[propertyData.operation] || "venta"}`}</h2>

        <div className="property-subheader-container">
          <h3>{propertyData.address}</h3>
          <div>
            <div className="property-published-price-number">
              <Icon component={IconPrice} />
              <h4>{displayPrice}</h4>
            </div>
            <span>
              {propertyData.operation === "sell"
                ? "Precio de venta publicado"
                : "Precio de arriendo publicado"}
            </span>
          </div>
        </div>
        {isMobile && <PropertySummaryImages propertyData={propertyData} />}
        <div className="map-container">
          <Map
            address={{
              value: propertyData.address,
              coords: {
                latitude: propertyData.latitude,
                longitude: propertyData.longitude,
              },
            }}
            config={{
              searchbar: false,
              polygon: false,
              markerLocation: true,
              ubication: false,
              zoomButtons: true,
              satelitalControl: false,
              propertiesAlerts: false,
              calculateArea: false,
              zoomWithWheel: false,
            }}
            withSearchBar={false}
          ></Map>
        </div>
        <div className="summary-data-icons">
          <span>
            <Icon component={IconBedroom} />
            {propertyData.bedRooms}
          </span>
          <span>
            <Icon component={IconBathroom} />
            {propertyData.fullBathrooms}
          </span>
          <span>
            <Icon component={IconSquareMeters} />
            {`${propertyData.coveredArea} m² útiles`}
          </span>
          <span>
            <Icon component={IconSquareMeters} />
            {`${propertyData.totalArea} m² totales`}
          </span>
        </div>

        <div className="summary-table">
          <div className="summary-table-item">
            <h4>Tipo de propiedad:</h4>
            <span>{propertyTypeFormatter[propertyData.propertyType]}</span>
          </div>
          <div className="summary-table-item">
            <h4>Cantidad de habitaciones:</h4>
            <span>{propertyData.bedRooms}</span>
          </div>
          <div className="summary-table-item">
            <h4>Tamaño construido (área útil):</h4>
            <span>{`${propertyData.coveredArea} m²`}</span>
          </div>
          <div className="summary-table-item">
            <h4>Cantidad de baños:</h4>
            <span>{propertyData.fullBathrooms}</span>
          </div>
          <div className="summary-table-item">
            <h4>Tamaño terreno (área total):</h4>
            <span>{`${propertyData.totalArea} m²`}</span>
          </div>
          <div className="summary-table-item">
            <h4>
              {propertyData.propertyType === "house"
                ? "Cantidad de pisos:"
                : "Piso:"}
            </h4>
            <span>
              {propertyData.floors ? propertyData.floors : "Sin información"}
            </span>
          </div>
          <div className="summary-table-item">
            <h4>Capacidad estacionamientos:</h4>
            <span>{`${propertyData.parkings} autos`}</span>
          </div>
          {propertyData.propertyType === "house" && (
            <div className="summary-table-item">
              <h4>¿Propiedad tiene piscina?</h4>
              <span>{propertyData.swimmingPool ? "Si" : "No"}</span>
            </div>
          )}
        </div>

        <div className="summary-card-grid dummy">
          <DataCard
            icon={IconPrice}
            title={`Precio ${
              operationFormatter[propertyData.operation]
            } publicado`}
            body={displayPrice}
            footer="Valor publicado por usuario"
            size={cardSize}
          ></DataCard>
          <DataCard
            icon={IconTassApp}
            title={`Precio estimado ${
              operationFormatter[propertyData.operation]
            }`}
            body={displayPrice}
            footer="Valor estimado por TassApp"
            size={cardSize}
          ></DataCard>

          <p className="property-value-conclusion">
            El valor de {operationFormatter[propertyData.operation]} publicado
            está
            {Math.random() < 0.5 ? (
              <em className="good-value"> 10,2% por debajo </em>
            ) : (
              <em className="bad-value"> 10,2% por arriba </em>
            )}
            del estimado por TassApp
          </p>

          <DataCard
            icon={IconTassApp}
            title="Rango estimado venta"
            body={`${displayPrice} - ${displayPrice}`}
            footer="Valor estimado por TassApp"
            outlined
            size={cardSize}
          ></DataCard>
          <p className="tassapp-explain-text">{explainText}</p>

          {propertyData.operation === "sell" ? (
            <Fragment>
              <DataCard
                icon={IconKeyHand}
                title="Valor estimado arriendo"
                body="UF 456/mes"
                footer="Valor estimado por TassApp"
                size={cardSize}
              ></DataCard>
              <DataCard
                icon={IconKeyHand}
                title="Rango de arriendo"
                body="UF 400 - 500/mes"
                footer="Valor estimado por TassApp"
                size={cardSize}
              ></DataCard>
            </Fragment>
          ) : (
            <Fragment>
              <DataCard
                icon={IconKeyHand}
                title="Valor estimado de venta"
                body="UF 210.000"
                footer="Valor estimado por TassApp"
                size={cardSize}
              ></DataCard>
              <DataCard
                icon={IconKeyHand}
                title="Rango de venta"
                body="UF 200.000 - 220.000"
                footer="Valor estimado por TassApp"
                size={cardSize}
              ></DataCard>
            </Fragment>
          )}

          <DataCard
            icon={IconCoinHand}
            title={`Rentabilidad Anual`}
            body="3,5%"
            footer="Valor estimado por TassApp"
            size={cardSize}
          ></DataCard>
          <DataCard
            icon={IconCoinHand}
            title="Rango de Rentabilidad"
            body="2,2% - 3,9%"
            footer="Valor estimado por TassApp"
            size={cardSize}
          ></DataCard>
        </div>
      </div>
      {!isMobile && <PropertySummaryImages propertyData={propertyData} />}
    </div>
  );
}
PropertySummary.propTypes = {
  propertyId: PropTypes.number.isRequired,
};

const PropertySummaryImages = ({ propertyData }) => (
  <div className="property-images">
    <Image.PreviewGroup>
      {propertyData.pictures.map((src, idx) => (
        <Image key={`${idx}-${src}`} src={src} />
      ))}
    </Image.PreviewGroup>
  </div>
);

PropertySummaryImages.propTypes = {
  propertyData: PropTypes.object,
};
