import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Select } from "antd";
import Icon from "@ant-design/icons";

import FeatureCard from "../Cards/FeatureCard";
import SearchDirectionBar from "../SearchDirectionBar";
import {
  illustrationComputer,
  IconChat,
  ArrowZigzag,
  IconTassAppEngine,
} from "../../assets";
import "./style.less";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

export default function Landing(props) {
  const {
    appraisalFormPropertyType,
    appraisalFormAddress,
    setAppraisalFormPropertyType,
    setAppraisalFormAddress,
    setAppraisalFormVisible,
  } = props;

  const [searchPropertyTypeValue, setSearchPropertyTypeValue] = useState(
    "house"
  );
  const [
    searchPropertyOperationValue,
    setSearchPropertyOperationValue,
  ] = useState("sell");
  const [searchAddressValue, setSearchAddressValue] = useState("");
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const buttonSize = isDesktop ? "large" : "medium";

  const ValorizationButton = () => (
    <Button
      onClick={() => setAppraisalFormVisible(true)}
      size={buttonSize}
      type="primary"
    >
      VALORIZAR
    </Button>
  );

  const SearchButton = () => (
    <Link
      to={`/properties?propertyTypeParam=${searchPropertyTypeValue}&propertyOperationType=${searchPropertyOperationValue}&searchAddress=${
        searchAddressValue.value
      }&latitude=${
        searchAddressValue.coords
          ? searchAddressValue.coords.latitude
          : undefined
      }&longitude=${
        searchAddressValue.coords
          ? searchAddressValue.coords.longitude
          : undefined
      }`}
    >
      <Button type="primary" size={buttonSize}>
        BUSCAR
      </Button>
    </Link>
  );

  return (
    <div className="landing-container">
      <div className="landing-main">
        <div className="landing-content">
          <div className="landing-main-item">
            <h1>Valorizar propiedades</h1>
            <div className="landing-box valorization">
              <SearchDirectionBar
                address={appraisalFormAddress}
                setAddress={(address) => {
                  setAppraisalFormAddress(address);
                }}
              />
              {!isMobile && (
                <Fragment>
                  <Select
                    placeholder="Casa o Departamento"
                    size={buttonSize}
                    onChange={(x) => setAppraisalFormPropertyType(x)}
                    value={appraisalFormPropertyType}
                  >
                    <Option value="house">Casa</Option>
                    <Option value="apartment">Departamento</Option>
                  </Select>

                  <ValorizationButton />
                </Fragment>
              )}
            </div>
            {isMobile && <ValorizationButton />}
          </div>
          <div className="landing-main-item">
            <h1>Buscar propiedades</h1>
            <div className="landing-box search">
              <SearchDirectionBar
                setAddress={(address) => {
                  setSearchAddressValue(address);
                }}
              />
              {!isMobile && (
                <Fragment>
                  <Select
                    placeholder="Casa o Departamento"
                    size={buttonSize}
                    onChange={(e) => setSearchPropertyTypeValue(e)}
                  >
                    <Option value="house">Casa</Option>
                    <Option value="apartment">Departamento</Option>
                  </Select>
                  <Select
                    placeholder="Venta o Arriendo"
                    size={buttonSize}
                    onChange={(e) => setSearchPropertyOperationValue(e)}
                  >
                    <Option value="sell">Venta</Option>
                    <Option value="rent">Arriendo</Option>
                  </Select>
                  <SearchButton />
                </Fragment>
              )}
            </div>
            {isMobile && <SearchButton />}
          </div>
          <Button
            className="btn-contact-us"
            icon={<Icon component={IconChat} />}
            size={buttonSize}
          >
            Contáctanos
          </Button>
        </div>
      </div>
      <div className="landing-tassapp-data landing-content">
        <h2>La nueva forma de entender el mercado inmobiliario</h2>
        <p>
          TassApp es la plataforma de analítica avanzada con la cual podrás
          saber lo que sucede en el mercado inmobiliario en sólo un par de
          clicks.
        </p>
        <img src={illustrationComputer} alt="" className="landing-image" />
        <p>
          Construimos herramientas que generan valor para nuestros usuarios en
          base a inteligencia artificial y big data en el mercado inmobiliario.
        </p>
      </div>

      <div className="landing-competitive-advantage landing-content">
        <h2>TassApp será tu ventaja competitiva</h2>
        <div id="competitive-advantage-illustration">
          <p id="text-1">
            Valorización instantánea de propiedades para venta y arriendo con
            inteligencia artificial
          </p>
          <p id="text-2">
            Plataforma para monitorear indicadores del mercado inmobiliario
          </p>
          <p id="text-3">
            Valorización masiva de cartera de propiedades con inteligencia
            artificial
          </p>
          <p id="text-4">Búsqueda inteligente de toda la oferta inmobiliaria</p>
          <ArrowZigzag />
        </div>

        <h3>Nuestra plataforma te ayudará a tomar las mejores desiciones.</h3>
      </div>

      <div className="landing-features landing-content">
        <h2>
          En TassApp nos preocupamos de entregarte todas herramientas
          necesarias.
        </h2>

        <div className="landing-card-grid">
          <FeatureCard
            title={"Valorización de propiedades"}
            text={
              "Valoriza una propiedad y obtén en instantes el valor estimado de venta y arriendo, incluyendo el rango en el cual se puede negociar."
            }
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc1.png"}
          ></FeatureCard>
          <FeatureCard
            text={
              "Con TassApp podrás encontrar en instantes las mejores oportunidades de inversion según tus preferenias."
            }
            title={"Búsqueda inteligente de oportunidades de inversión"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc2.png"}
          ></FeatureCard>
          <FeatureCard
            text={
              "Podrás saber qué está pasando en el mercado inmobiliario en cada manzana, barrio, comuna y ciudad."
            }
            title={"Monitoreo de indicadores del mercado"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc3.png"}
          ></FeatureCard>

          <FeatureCard
            text={
              "Sabrás el posicionamiento de las propiedades del portfolio de tu empresa, detectando cuales están por sobre o por debajo del precio de mercado."
            }
            title={"Valorización masiva de portafolio"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc4.png"}
          ></FeatureCard>
          <FeatureCard
            text={
              "Con nuestra API podrás incluir valorizaciones en los flujos internos de tu empresa fácilmente."
            }
            title={"API de estimación de valor"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc5.png"}
          ></FeatureCard>
          <FeatureCard
            text={
              "Con nuestra herramienta de creación de polígonos, podrás medir el metraje en m² de la propiedad que deseas valorizar."
            }
            title={"Herramienta de medición de área total con polígonos"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc6.png"}
          ></FeatureCard>

          <FeatureCard
            text={
              "Con nuestra herramienta de creación de polígonos, podrás medir el metraje en m² de la propiedad que deseas valorizar."
            }
            title={"Herramienta de polígonos en el mapa"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc7.png"}
          ></FeatureCard>
          <FeatureCard
            text={
              "Puedes ver y administrar o descargar todas las valorizaciones que haz realizado."
            }
            title={"Guarda y administra tu historial de valorizaciones"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc8.png"}
          ></FeatureCard>
          <FeatureCard
            text={
              "Con nuestra búsqueda inteligente podrás saber que propiedades están por sobre o por debajo del valor estimado por TassApp."
            }
            title={"Ver detalles de las propiedades de la búsqueda"}
            image={"https://tassapp-public-assets.s3.amazonaws.com/pc9.png"}
          ></FeatureCard>
        </div>
      </div>

      <div className="landing-how-it-works landing-content">
        <h2>Pero... ¿Cómo funciona TassApp?</h2>

        <div id="how-it-works-illustration">
          <div id="text-1">
            <span>Portales inmobiliarios</span>
            <span>{isMobile ? "SII" : "Servicio de Impuestos Internos"}</span>
            <span>INE</span>
            <span>Entre otros...</span>
          </div>
          <IconTassAppEngine />
          <div id="text-2">
            <span>Valorización de propiedades</span>
            <span>Indicadores de mercado</span>
            <span>Análisis de data automatizado</span>
          </div>
        </div>

        <div className="landing-info-data-card">
          Combinamos datos del mercado inmobiliario con:
          <ul>
            <li>Indicadores macroeconómicos</li>
            <li> Datos provenientes de censos</li>
            <li>Métricas ambientales y geográficas</li>
            <li>Entre otros...</li>
          </ul>
          Con el fin de entregar un análisis más completo y transparente de las
          condiciones del mercado y sus cambios de tendencias.
        </div>
      </div>

      <div className="landing-footer">
        <span>2021, TassApp S.A</span>
        <a>API</a>
        <a>Danos tu opinión</a>
        <a>Política de privacidad</a>
        <a>contacto@tassapp.com</a>
        <a>About us</a>
        <a>Preguntas frecuentes</a>
        <a>Términos y condiciones</a>
      </div>
    </div>
  );
}

Landing.propTypes = {
  appraisalFormPropertyType: PropTypes.string,
  appraisalFormAddress: PropTypes.object,
  setAppraisalFormPropertyType: PropTypes.func,
  setAppraisalFormAddress: PropTypes.func,
  setAppraisalFormVisible: PropTypes.func,
};
